<template>
  <v-container fluid>
    <v-card class="mx-auto my-12" max-width="">
      <v-card-text>
        <v-card-title>
          {{ cliente.name }}
        </v-card-title>
        <v-list-item two-line v-if="cliente.user">
          <v-list-item-content>
            <v-list-item-title>Administrador</v-list-item-title>
            <v-list-item-subtitle>{{ cliente.user.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-if="cliente.user">
          <v-list-item-content>
            <v-list-item-title>Responsaveis</v-list-item-title>
            <template v-for="responsavel in cliente.responsibles">
              <v-list-item-subtitle :key="responsavel.id + 'id'">{{
                responsavel.name
              }}</v-list-item-subtitle>
            </template>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Email</v-list-item-title>
            <v-list-item-subtitle>{{
              cliente.email_administradora
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Cnpj</v-list-item-title>
            <v-list-item-subtitle>{{ cliente.cnpj }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>Nome Administradora</v-list-item-title>
            <v-list-item-subtitle>{{
              cliente.nome_administradora
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-card-text>
        <lista-demandas v-if="cliente.demands" :list="cliente.demands" />
      </v-card-text>

      <v-tabs v-model="tab" class="mb-4">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-show="tab == 0">
          <div class="text-center">
            <v-row>
              <v-col cols="8">
                <v-file-input
                  v-model="arquivos"
                  counter
                  multiple
                  show-size
                ></v-file-input>
              </v-col>
              <v-col cols="4">
                <v-btn color="green" class="mb-4" @click="upload('contrato')">
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <FileCard
            v-for="contrato in contratos"
            :key="contrato.id"
            :file="contrato"
            @fileDiscard="showData()"
          ></FileCard>
        </v-tab-item>
        <v-tab-item v-show="tab == 1">
          <div class="text-center">
            <v-row>
              <v-col cols="8">
                <v-file-input
                  v-model="arquivos"
                  counter
                  multiple
                  show-size
                ></v-file-input>
              </v-col>
              <v-col cols="4">
                <v-btn color="green" class="mb-4" @click="upload('essencial')">
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <FileCard
            v-for="documento in documentos"
            :key="documento.id"
            :file="documento"
            :type="'Documentos Essenciais'"
            @fileDiscard="showData()"
          ></FileCard>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import ToastService from "@/services/ToastService";
import ClienteService from "@/services/Cliente/ClienteService.js";
import ArquivosService from "@/services/Arquivos/ArquivosService";
import AuthorizationService from "@/services/AuthorizationService";
import FileCard from "@/components/Files/FileCard";
import ListaDemandas from "../../components/Listas/ListaDemandas";

export default {
  name: "Show",

  components: {
    FileCard,
    ListaDemandas,
  },

  data: () => ({
    AuthorizationService: AuthorizationService,
    ClienteService: new ClienteService(""),
    ArquivosService: new ArquivosService(""),
    tab: null,
    items: ["Contrato", "Documentos Essenciais"],
    contratos: [],
    documentos: [],
    arquivos: [],
    selected: undefined,
    id: undefined,
    cliente: null,
  }),

  created: async function() {
    const id = this.$route.params.id;
    this.id = id;
    if (id !== undefined) {
      this.loading = true;
      await this.getCliente();
    }
  },

  methods: {
    async getCliente() {
      try {
        const cliente = await this.ClienteService.show("customers/" + this.id);

        this.cliente = cliente;
      } catch (error) {
        ToastService("Não foi possível consultar os dados.", "error");
      } finally {
        this.loading = false;
      }
    },
    async showData() {
      try {
        const arquivos = await this.ArquivosService.index(
          "arquivos/lista/contrato/" + this.id
        );

        this.contratos = arquivos.arquivos;

        const documentos = (this.documentos = await this.ArquivosService.index(
          "arquivos/lista/essencial/" + this.id
        ));

        this.documentos = documentos.arquivos;
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");
      } finally {
        this.loading = false;
      }
    },
    async upload(pasta) {
      try {
        this.arquivos.forEach(async (file) => {
          var formData = new FormData();
          formData.append("pasta", pasta);

          formData.append("arquivos", file);

          formData.append("subpasta", this.id);

          await this.ArquivosService.store("arquivos", formData);
        });

        await this.showData();

        ToastService("Upload realizado.", "success");
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");
      }
    },

    async getFile() {
      try {
        const url = await this.ClienteService.show(
          "customers/download/xlsx/" + this.id
        );
        if (typeof process.env.VUE_URL == "undefined") {
          window.open("https://wsjus.com.br/" + url);
          return;
        }

        window.open(process.env.VUE_URL + url);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
