<template>
  <v-card class="mx-auto mb-2 mt-2" max-width="344" outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="overline mb-4">{{ type }}</div>
        <v-list-item-title class="headline mb-1">
          <v-text-field
            type="text"
            label="Nome"
            autocomplete="off"
            :autofocus="true"
            v-model="file.nome"
          ></v-text-field>
        </v-list-item-title>
        <v-list-item-subtitle> {{ file.ext }} </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar tile size="80" color="grey">
        <v-icon x-large> fas fa-file-archive </v-icon>
      </v-list-item-avatar>
    </v-list-item>
    <v-card-actions>
      <v-btn
        color="green"
        rounded
        target="_blank"
        :href="'https://api-wsjus.herokuapp.com/storage/' + file.path"
      >
        Baixar
      </v-btn>
      <v-btn color="gray" rounded @click="rename"> Renomear </v-btn>
      <v-btn color="red" rounded @click="destroy"> Excluir </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import ArquivosService from "@/services/Arquivos/ArquivosService";
export default {
  props: {
    file: {},
    type: {
      default: "Contrato",
    },
  },

  data: () => ({
    ArquivosService: new ArquivosService("arquivos"),
  }),

  methods: {
    async destroy() {
      try {
        this.ArquivosService.storeOrUpdate("destroy", {
          path: this.file.path,
        });

        ToastService("Excluido com sucesso", "success");

        this.$emit("fileDiscard");
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");
      }
    },

    async rename() {
      try {
        this.ArquivosService.storeOrUpdate("inserir/" + this.file.nome, {
          path: this.file.path,
        });
        console.log(this.file.nome)
        ToastService("Nome atualizado.", "success");
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");
      }
    },
  },
};
</script>

<style>
</style>